// Check which button click.
// let buttonElements = document.getElementsByClassName('faq-title');
// for (let $i = 0; $i < buttonElements.length; $i++) {
//   buttonElements[$i].onclick = function (){
//     if (buttonElements[$i].classList.contains('active') == true) {
//       buttonElements[$i].classList.remove('active');
//     } else {
//       buttonElements[$i].classList.add('active');
//     }
//   }
// }
// Check which button click.
let headerButtonElements = document.getElementsByClassName('other-button');
for (let $i = 0; $i < headerButtonElements.length; $i++) {
  headerButtonElements[$i].onclick = function (){
    if (headerButtonElements[$i].classList.contains('active') == true) {
      headerButtonElements[$i].classList.remove('active');
    } else {
      headerButtonElements[$i].classList.add('active');
    }
  }
}


window.addEventListener('load' ,function() {
  setTimeout(() => {document.getElementsByClassName('cross-label')[0].classList.add('active');}, 600);
});